import { render, staticRenderFns } from "./tabTitle.vue?vue&type=template&id=055c87ce&scoped=true&"
import script from "./tabTitle.vue?vue&type=script&lang=js&"
export * from "./tabTitle.vue?vue&type=script&lang=js&"
import style0 from "./tabTitle.vue?vue&type=style&index=0&id=055c87ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "055c87ce",
  null
  
)

export default component.exports
<template>
    <div class="fl_c al_c">
        <el-carousel indicator-position="inside" arrow="never" class="swiperBox" v-if="!$common.mobile()">
            <el-carousel-item v-for="(item,index) in imgList" :key="index">
                <div @click="goPage(item)">
                    <img class="imgsty" :src="item.image" alt="">
                    <div class="grayBox"></div>
                </div>
            </el-carousel-item>
        </el-carousel>
        <div class="swiperSty1" v-if="$common.mobile()">
            <el-carousel v-if="$common.mobile()" arrow="never" trigger="click">
                <el-carousel-item v-for="item in imgList" :key="item.id">
                    <img height="100%" width="100%" :src="item.image" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <div v-if="!$common.mobile()">
            <div class="blueNotice" v-if="productList.length>0" @click="goPage(productList[0])">
                <div style="color:#666666 " v-if="productList[0].showTime">
                    {{productList[0].showTime.substring(0,10)}}&nbsp;&nbsp;|&nbsp;&nbsp;{{productList[0].title}}
                </div>
            </div>
        </div>

        <!----------------------------------------------------------------------------------------------------------------->
        <div class="fl_r noticeBox ju_b" v-if="!$common.mobile()">
            <div class="fl_c noticeBoxItem">
                <tab-title v-if="sortId1.length>0" :tabList="sortId1" :first="sortId1[0].id" :NoticeList="sortArr1"
                           :headerList="headerList"/>
                <div class="secondSwiper">
                    <el-carousel indicator-position="inside" arrow="never" v-if="sortArr10&&sortArr10.length>0">
                        <el-carousel-item v-for="(item,index) in sortArr10" :key="index">
                            <div @click="goPage(item)">
                                <img class="imgsty" :src="item.titleImg" alt="">
                                <div class="grayBox" style="background-color: white"></div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <div class="noticeBoxItem" v-if="sortArr2&&sortArr2.length>0">
                <img :src="sortArr2[0].titleImg" alt="" class="videoBox" @click="goPage(sortArr2[0])"
                     style="cursor: pointer">
            </div>
        </div>
        <div class="fl_r noticeBox ju_b" style="width: 100%;height: 200px" v-if="$common.mobile()">
            <div class="fl_c noticeBoxItem" style="width: 100%">
                <tab-title v-if="sortId1.length>0" :tabList="sortId1" :first="sortId1[0].id" :NoticeList="sortArr1"
                           :headerList="headerList"/>
            </div>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <!--        <center-icons v-if="!$common.mobile()"/>-->
        <!----------------------------------------------------------------------------------------------------------------->
        <div class="infoList fl_r" v-if="!$common.mobile()">
            <div class="infoListItem" v-for="(item,index) in sortId3" :key="index">
                <box-tittle :title="item.name" :headerList="headerList" :first="item.id"/>
                <info-list v-if="index==0" :arr="sortArr3.arr1.slice(0,6)"/>
                <info-list v-if="index==1" :arr="sortArr3.arr2.slice(0,6)"/>
                <info-list v-if="index==2" :arr="sortArr3.arr3.slice(0,6)"/>
                <info-list v-if="index==3" :arr="sortArr3.arr4.slice(0,6)"/>
            </div>
        </div>
        <div class="infoList fl_c" style="width: 100%;height: auto;" v-if="$common.mobile()">
            <div class="infoListItem" style="width: 100%" v-if="sortId3.length>0">
                <box-tittle :title="sortId3[0].name" :headerList="headerList" :first="sortId3[0].id"/>
                <info-list :arr="sortArr3.arr1.slice(0,4)"/>
            </div>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <div class="goodsBox" v-if="!$common.mobile()&&sortArr4.length>0">
            <title-with-border v-if="sortId4.length>0" :title="sortId4[0].name"/>
            <div class="fl_r ju_b">
                <div class="goodsBoxItem fl_c" v-for="(item,index) in sortArr4" :key="index" @click="goPage(item)">
                    <img class="goodsImg" :src="item.titleImg" alt="">
                    <div class="goodsTitle fl_c">
                        <div style="height: 42px;">{{item.title}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="goodsBox" style="width: 100%;height: auto" v-if="$common.mobile()&&sortArr4.length>0">
            <title-with-border v-if="sortId4.length>0" :title="sortId4[0].name"/>
            <div class="fl_c fl_w ju_b">
                <div class="goodsBoxItem fl_c" style="width: 100%;" v-for="(item,index) in sortArr4" :key="index">
                    <img class="goodsImg" :src="item.titleImg" alt="">
                    <div class="goodsTitle fl_c">
                        <div style="height: 42px;">{{item.title}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <div class="reportBox fl_r ju_b mar_top" v-if="!$common.mobile()">
            <div class="reportBoxLeft fl_c">
                <tab-title v-if="sortId5.length>0" :tabList="sortId5" :first="sortId5[0].id" :NoticeList="sortArr5"
                           :headerList="headerList"/>
            </div>
            <div class="reportBoxRight fl_c ju_b">
                <title-with-border title="快捷指引"/>
                <div class="reportBoxRightItem">
                    <div class="reportBoxRightItemBox" style="padding: 15px;background-color: white">
                        <div class="reportTitle">交易商指引</div>
                        <div class="fl_r fl_w" style="width: 100%;height: 100%;">
                            <div v-for="(item,index) in sortId6" @click="goSecondPage(item.id)" :key="index" style="width: 25%;
                            text-align: center;line-height: 50px;color: rgba(255, 255, 255, 0.8);cursor: pointer">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reportBoxRightItem">
                    <div class="reportBoxRightItemBox" style="padding: 15px;background-color: white">
                        <div class="reportTitle">业务指引</div>
                        <div class="fl_r fl_w" style="width: 100%;height: 100%;">
                            <div v-for="(item,index) in sortId7" @click="goSecondPage(item.id)" :key="index" style="width: 25%;
                            text-align: center;line-height: 50px;color: rgba(255, 255, 255, 0.8);cursor: pointer">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="reportBox fl_c ju_b mar_top" style="width: 100%;height: auto" v-if="$common.mobile()">
            <div class="reportBoxLeft fl_c" style="width: 100%;height: 200px">
                <tab-title v-if="sortId5.length>0" :tabList="sortId5" :first="sortId5[0].id" :NoticeList="sortArr5"
                           :headerList="headerList"/>
            </div>
            <div class="reportBoxRight fl_c ju_b" style="width: 100%;">
                <title-with-border title="快捷指引"/>
                <div class="reportBoxRightItem mar_top">
                    <div class="reportBoxRightItemBox" style="padding: 15px;background-color: white">
                        <div class="reportTitle">交易商指引</div>
                        <div class="fl_r fl_w" style="width: 100%;height: 100%;">
                            <div v-for="(item,index) in sortId6" @click="goSecondPage(item.id)" :key="index" style="width: 25%;
                            text-align: center;line-height: 50px;color: rgba(255, 255, 255, 0.8);
                            cursor: pointer;font-size: 13px">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="reportBoxRightItem mar_top">
                    <div class="reportBoxRightItemBox" style="padding: 15px;background-color: white">
                        <div class="reportTitle">业务指引</div>
                        <div class="fl_r fl_w" style="width: 100%;height: 100%;">
                            <div v-for="(item,index) in sortId7" @click="goSecondPage(item.id)" :key="index" style="width: 25%;text-align: center;
                            line-height: 25px;color: rgba(255, 255, 255, 0.8);
                            cursor: pointer;height: 25px;font-size: 13px">
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----------------------------------------------------------------------------------------------------------------->
        <div class="newsBox fl_r ju_b mar_bottom" v-if="!$common.mobile()">
            <div class="newsBoxItem">
                <tab-title v-if="sortId8.length>0" :tabList="sortId8" :first="sortId8[0].id" :NoticeList="sortArr8"
                           :headerList="headerList"/>
            </div>
            <div class="newsBoxItem">
                <tab-title v-if="sortId9.length>0" :tabList="sortId9" :first="sortId9[0].id" :NoticeList="sortArr9"
                           :headerList="headerList"/>
            </div>
        </div>
        <div class="newsBox fl_c ju_b mar_bottom" style="width: 100%;height: auto;" v-if="$common.mobile()">
            <div class="newsBoxItem" style="width: 100%;height: 200px">
                <tab-title v-if="sortId8.length>0" :tabList="sortId8" :first="sortId8[0].id" :NoticeList="sortArr8"
                           :headerList="headerList"/>
            </div>
            <div class="newsBoxItem" style="width: 100%;height: 200px">
                <tab-title v-if="sortId9.length>0" :tabList="sortId9" :first="sortId9[0].id" :NoticeList="sortArr9"
                           :headerList="headerList"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {getSwiper, getarticlesInfo, getColumnTree} from '../../utils/api/index.js'
    import BoxTittle from "../../components/BoxTittle/BoxTittle";
    import infoList from "../../components/infoList/infoList";
    import tabNotice from "../../components/tabNotice/tabNotice";
    import centerIcons from "../../components/centerIcons/centerIcons";
    import titleWithBorder from "../../components/titleWithBorder/titleWithBorder";
    import TabTitle from "../../components/tabTitle/tabTitle";
    import TittleList from "../../components/TittleList/TittleList";
    import greenAsider from "../../components/asiders/greenAsider";

    export default {
        name: "index",
        components: {
            TittleList, TabTitle, BoxTittle,
            infoList, tabNotice, centerIcons, titleWithBorder,
            greenAsider
        },
        data() {
            return {
                imgList: [],
                consultList: [],
                noticeList: [],
                productList: [],
                NoticeList: [],


                sortId1: [],
                sortArr1: [],
                sortId2: [],
                sortArr2: [],
                sortId3: [],
                sortArr3: {
                    arr1: [],
                    arr2: [],
                    arr3: [],
                    arr4: [],
                },
                sortId4: [],
                sortArr4: [],
                sortId5: [],
                sortArr5: [],
                sortId6: [],
                sortArr6: [],
                sortId7: [],
                sortArr7: [],
                sortId8: [],
                sortArr8: [],
                sortId9: [],
                sortArr9: [],
                sortId10: [],
                sortArr10: [],
                headerList: [],
            }
        },
        mounted() {
            this.$store.commit('setWeixing', false);
            this.getColumnTree()
        },
        methods: {
            goSecondPage(id){
                this.headerList.map(item=>{
                    item.subArticles.map(child=>{
                        if(child.id.toString()==id){
                            this.$store.commit('setFormalItem', item);
                            this.$store.commit('setChildItem', child);
                            this.$router.push({ path: '/secondPage', query: { columnId: child.id } })
                        }
                    })
                })
            },
            async getColumnTree() {
                let res = await getColumnTree()
                res.data.map(async item => {
                    this.headerList = res.data
                    if (item.columnType == '01') {
                        this.getSwiper(item.id)
                        let arr1 = []
                        let arr2 = []
                        let arr3 = []
                        let arr4 = []
                        let arr5 = []
                        let arr6 = []
                        let arr7 = []
                        let arr8 = []
                        let arr9 = []
                        let arr10 = []
                        item.subArticles.map(async child => {
                            if (child.sequence == 1) {
                                this.sortId1.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr1.push(child.id.toString())
                            }
                            if (child.sequence == 2) {
                                this.sortId2.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr2.push(child.id.toString())
                            }
                            if (child.sequence == 3) {
                                this.sortId3.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr3.push(child.id.toString())
                            }
                            if (child.sequence == 4) {
                                this.sortId4.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr4.push(child.id.toString())
                            }
                            if (child.sequence == 5) {
                                this.sortId5.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr5.push(child.id.toString())
                            }
                            if (child.sequence == 6) {
                                this.sortId6.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr6.push(child.id.toString())
                            }
                            if (child.sequence == 7) {
                                this.sortId7.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr7.push(child.id.toString())
                            }
                            if (child.sequence == 8) {
                                this.sortId8.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr8.push(child.id.toString())
                            }
                            if (child.sequence == 9) {
                                this.sortId9.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr9.push(child.id.toString())
                            }
                            if (child.sequence == 10) {
                                this.sortId10.push({
                                    id: child.id.toString(),
                                    name: child.name
                                })
                                arr10.push(child.id.toString())
                            }
                        })
                        let res1 = await getarticlesInfo({
                            columnId: arr1.toString()
                        })
                        this.sortArr1 = res1.data

                        let res2 = await getarticlesInfo({
                            columnId: arr2.toString()
                        })
                        this.sortArr2 = res2.data

                        let res31 = await getarticlesInfo({
                            columnId: arr3[0].toString()
                        })
                        let res32 = await getarticlesInfo({
                            columnId: arr3[1].toString()
                        })
                        let res33 = await getarticlesInfo({
                            columnId: arr3[2].toString()
                        })
                        let res34 = await getarticlesInfo({
                            columnId: arr3[3].toString()
                        })
                        this.sortArr3.arr1 = res31.data
                        this.sortArr3.arr2 = res32.data
                        this.sortArr3.arr3 = res33.data
                        this.sortArr3.arr4 = res34.data
                        let res4 = await getarticlesInfo({
                            columnId: arr4.toString()
                        })
                        this.sortArr4 = res4.data

                        let res5 = await getarticlesInfo({
                            columnId: arr5.toString()
                        })
                        this.sortArr5 = res5.data

                        let res8 = await getarticlesInfo({
                            columnId: arr8.toString()
                        })
                        this.sortArr8 = this.$common.mobile() ? res8.data.slice(0, 5) : res8.data.slice(0, 7)
                        let res9 = await getarticlesInfo({
                            columnId: arr9.toString()
                        })
                        this.sortArr9 = this.$common.mobile() ? res9.data.slice(0, 5) : res9.data.slice(0, 7)
                        let res10 = await getarticlesInfo({
                            columnId: arr10.toString()
                        })
                        this.sortArr10 = res10.data.slice(0, 5)
                    }
                })
            },
            async getSwiper(id) {
                let res = await getSwiper({
                    columnId: id
                })
                this.imgList = res.data
            },
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId
                    }
                });
            }
        },
    }
</script>

<style scoped lang="scss">
    .swiperBox {
        width: 1200px;
        height: 420px;

        ::v-deep .el-carousel__container {
            height: 420px !important;
        }
    }

    ::v-deep .el-carousel__button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin: 0 10px;
    }

    .grayBox {
        width: 100%;
        height: 35px;
        background-color: rgba(0, 0, 0, 0.3);
        color: white;
        position: absolute;
        bottom: 0;
        font-family: '微软雅黑';
        font-size: 16px;
        padding: 5px;
    }

    .imgsty {
        width: 100%;
        height: 100%;
    }

    .blueNotice {
        width: 1200px;
        margin: 7px auto 0px;
        height: 40px;
        line-height: 40px;
        background: #d9e3ff;
        text-indent: 10px;
        cursor: pointer;
    }

    .infoList {
        width: 1200px;
        height: 250px;
        margin-top: 10px;

        .infoListItem {
            width: 25%;
            height: 100%;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-right: 15px;
        }

        .infoListItem:last-child {
            margin-right: 0;
        }
    }

    .noticeBox {
        width: 1200px;
        height: 400px;
        margin-top: 10px;

        .noticeBoxItem {
            width: 590px;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

            .secondSwiper {
                width: 100%;
                cursor: pointer;
                height: 250px;

                ::v-deep .el-carousel__container {
                    height: 250px !important;
                }
            }

            .videoBox {
                width: 100%;
                height: 100%;
            }
        }

    }

    .goodsBox {
        width: 1200px;
        height: 345px;
        margin-top: 20px;

        .goodsBoxItem {
            margin-top: 10px;
            width: 32%;
            height: 310px;
            cursor: pointer;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

            .goodsImg {
                width: 100%;
                height: 200px;
            }

            .goodsTitle {
                width: 100%;
                height: 110px;
                padding: 15px;
            }
        }
    }

    .reportBox {
        width: 1200px;
        height: 320px;

        .reportBoxLeft {
            width: 570px;
            height: 100%;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        }

        .reportBoxRight {
            width: 610px;
            height: 100%;

            .reportBoxRightItem {
                width: 100%;
                height: 140px;
                background-color: #195B40;
                border-radius: 4px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            }

            .reportBoxRightItemBox {
                width: 100%;
                height: 130px;
                padding: 15px;
                background-color: #195B40 !important;

                .reportTitle {
                    color: white;
                    font-weight: bold;
                }
            }
        }
    }

    .newsBox {
        width: 1200px;
        height: 260px;
        margin-top: 20px;

        .newsBoxItem {
            width: 590px;
            height: 100%;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        }
    }

    /**********************************phone**********************/
    .swiperSty1 {
        width: 100%;

        .el-carousel__item {
            color: #475669;
            height: 175px;
        }

        .el-carousel {
            margin-top: 50px;
            /*position: absolute;*/
            /*top: 0;*/
            /*z-index: 0;*/
            width: 100%;
            height: 175px !important;
        }

        ::v-deep .el-carousel__container {
            height: 175px !important;
        }
    }
</style>

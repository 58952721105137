<template>
    <div style="width: 100%;height: 100%;background-color: #fff">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="商品公告" name="1">
                <div v-for="(item,index) in 4" :key="index" class="titleSty white_space_one">
                    关于调整履约订金的公告
                </div>
            </el-tab-pane>
            <el-tab-pane label="公司公告" name="2">
                <div v-for="(item,index) in 4" :key="index" class="titleSty white_space_one">
                    关于调整订单市场交易时间的公告
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        name: "tabNotice",
        components: {},
        data() {
            return {
                activeName: '1',
            }
        },
        mounted() {

        },
        methods: {
            handleClick(tab, event) {

            }
        },
    }
</script>

<style scoped lang="scss">
    .titleSty {
      color: #666666;
      font-size: 15px;
      cursor: pointer;
      width: 500px;
      margin-left: 10px;
      padding: 1px 0;
    }
  .titleSty:hover{
    color: #195B40;
  }
  ::v-deep .el-tabs__item:hover{
    color: #195B40;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #195B40;
  }
  ::v-deep .el-tabs__active-bar{
    background-color: #195B40;
  }
</style>

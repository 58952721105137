<template>
    <div class="fl_r ju_b" style="width: 100%;height: 35px">
<!--        <img src="../../assets/img/1.png" alt="" style="margin:0 10px">-->
        <div class="fl_r ju_b" style="width: 100%;height: 100%;line-height: 35px;padding-left: 20px">
            <div class="title">{{title}}</div>
            <div class="more" style="right: 0;cursor: pointer" @click="goSecondPage">更多</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BoxTittle",
        components: {},
        props: {
            title: {
                type: String,
                default: '当前标题'
            },
            first: {
                type: String,
                require: true,
            },
            headerList:{
                type: Array,
                require: true,
            },
        },
        data() {
            return {
                activeName: this.first,
            }
        },
        mounted() {

        },
        methods: {
            goSecondPage(){
                this.headerList.map(item=>{
                    item.subArticles.map(child=>{
                        if(child.id.toString()==this.activeName){
                            this.$store.commit('setFormalItem', item);
                            this.$store.commit('setChildItem', child);
                            this.$router.push({ path: '/secondPage', query: { columnId: child.id } })
                        }
                    })
                })
            },
        },
    }
</script>

<style scoped lang="scss">
    .title {
        width: auto;
        font-size: 20px;
        font-family: 微软雅黑;
        color: #195B40;
        font-size: 16px;
    }

    .more {
        font-family: Microsoft YaHei;
        color: #195B40;
        font-size: 16px;
        font-weight: normal;
        padding:0 10px;
    }
</style>

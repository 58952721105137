<template>
    <div class="fl_r" style="width: 100%;height: 20px">
        <div class="borderSty"></div>
        <div class="titleSty">{{title}}</div>
    </div>
</template>

<script>
    export default {
        name: "titleWithBorder",
        components: {},
        props: {
            title: {
                type: String,
                default: '当前标题'
            }
        },
        data() {
            return {}
        },
        mounted() {

        },
        methods: {},
    }
</script>

<style scoped lang="scss">
    .borderSty {
        width: 4px;
        height: 20px;
        background: #195B40;
    }

    .titleSty {
        font-size: 20px;
        text-indent: 14px;
        line-height: 1;
        color: #666;
    }
</style>

<template>
    <div class="contain  ju_c al_c">
        <div v-if="titleList">
            <div  class="item fl_r ju_b" v-for="(item,index) in titleList" :key="index"  @click="goPage(item)">
                <div class="title white_space_one" >
                    【{{activeName}}】{{item.title}}
                </div>
                <div class="date" v-if="item.showTime">{{item.showTime.substring(0,10)}}</div>
            </div>
        </div>
        <div v-else >
            <el-empty description="暂无数据" style="width: 100%;height: 100%;"></el-empty>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TittleList",
        components: {},
        props:['titleList','activeName'],
        data() {
            return {}
        },
        mounted() {

        },
        methods: {
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId:item.id,
                        columnId:item.columnId
                    }
                });
            }
        },
    }
</script>

<style scoped lang="scss">
    .contain {
        width:100%;
    }

    .item {
        width: 90%;
        height: 27px;
        color: #666666;
        cursor: pointer;
    }
    .item:hover{
        color: #195B40;
    }
    .title {
        width: 75%;
        font-family: Microsoft YaHei;
        font-size: 16px;
        font-weight: normal;
    }

    .date {
        font-family: Microsoft YaHei;
        font-size: 15px;
        font-weight: normal;
        color: #000;
        white-space: nowrap;
        line-height: 27px;
    }
</style>

import { render, staticRenderFns } from "./BoxTittle.vue?vue&type=template&id=e57bc6ac&scoped=true&"
import script from "./BoxTittle.vue?vue&type=script&lang=js&"
export * from "./BoxTittle.vue?vue&type=script&lang=js&"
import style0 from "./BoxTittle.vue?vue&type=style&index=0&id=e57bc6ac&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e57bc6ac",
  null
  
)

export default component.exports
<template>
    <div class="contain fl_r ju_c al_c">
        <div class="itemSty fl_c ju_c al_c" v-for="(item,index) in arr" :key="index">
            <img class="iconSty" src="" alt="">
            <div class="titleSty">{{item.name}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "centerIcons",
        components: {},
        data() {
            return {
                arr: [{
                    id: 1,
                    name: '订单交易'
                }, {
                    id: 2,
                    name: '协议交易'
                }, {
                    id: 3,
                    name: '在线开户'
                }, {
                    id: 4,
                    name: '签约流程'
                }, {
                    id: 5,
                    name: '交易日历'
                }, {
                    id: 6,
                    name: '下载中心'
                }, {
                    id: 7,
                    name: '用户登录'
                }]
            }
        },
        mounted() {

        },
        methods: {},
    }
</script>

<style scoped lang="scss">
    .contain {
        width: 1200px;
        height: 100px;
        margin: 10px 0;
        background-color: #195B40;
    }

    .itemSty {
        padding: 0 40px;
        cursor: pointer;
    }

    .iconSty {
        width: 60px;
        height: 60px;
    }

    .titleSty {
        color: white;
        width: auto;
        line-height: 18px;
        height: 18px;
        padding-top: 5px;
    }
</style>

<template>
    <div class="fl_c" style="width: 100%;height: 100%;">
        <div class="fl_r ju_b titleTop pos_r">
            <el-tabs v-model="activeName" @tab-click="handleClick" class="mar_about" style="width: 100%;">
                <el-tab-pane :label="item.name" :name="item.id" v-for="(item,index) in tabList" :key="index">
                    <div>
                        <tittle-list :activeName="item.name" :titleList="titleList"/>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="more pos_a" style="right: 0;cursor: pointer" @click="goSecondPage">更多</div>
        </div>
    </div>
</template>

<script>
    import TittleList from "../TittleList/TittleList";
    import infoList from "../infoList/infoList";

    export default {
        name: "tabTitle",
        components: {TittleList, infoList},
        props: {
            tabList: {
                type: Array,
                require: true,
            },
            first: {
                type: String,
                require: true,
            },
            NoticeList: {
                type: Array,
                require: true,
            },
            headerList: {
                type: Array,
                require: true,
            },
        },
        data() {
            return {
                activeName: this.first,
                titleList: [],
            }
        },
        watch: {
            NoticeList: {
                handler(newVal, oldVal) {
                    if (newVal && newVal.length > 0) {
                        newVal.forEach(item => {
                            if (item.columnId == this.activeName) {
                                this.titleList.push(item)
                            }
                        })
                        this.titleList = this.$common.mobile() ? this.titleList.slice(0, 5) : this.titleList.slice(0, 10)
                    }
                },
                immediate: true // 立即执行一次 handler
            }
        },
        methods: {
            goSecondPage() {
                this.headerList.map(item => {
                    item.subArticles.map(child => {
                        if (child.id.toString() == this.activeName) {
                            this.$store.commit('setFormalItem', item);
                            this.$store.commit('setChildItem', child);
                            this.$router.push({ path: '/secondPage', query: { columnId: child.id } })
                        }
                    })
                })
            },
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId
                    }
                });
            },
            handleClick(tab, event) {
                this.titleList = []
                if (this.NoticeList && this.NoticeList.length > 0) {
                    this.NoticeList.map(item => {
                        if (JSON.stringify(item.columnId) == this.activeName) {

                            this.titleList.push(item)
                        }
                    })
                }

            }
        },
    }
</script>

<style scoped lang="scss">
    .titleTop {
        height: 43px;
        width: 100%;
        border-bottom: 2px solid rgba(0, 0, 0, 0.08);
        color: #333;

        .more {
            margin-right: 15px;
            color: #195B40;
            font-size: 15px;
            line-height: 43px;
        }
    }

    ::v-deep .el-tabs__active-bar {
        background-color: #195B40;
    }

    ::v-deep .el-tabs__item.is-active {
        color: #195B40;
    }

    ::v-deep .el-tabs__item:hover {
        color: #195B40;
    }

    ::v-deep .el-tabs__item {
        height: 43px;
        line-height: 43px;
    }

    ::v-deep .el-tabs__header {
        margin-bottom: 8px;
    }

    .imgSty {
        width: 200px;
        height: 200px;
        cursor: pointer;
    }

    .infoBox {
        width: 340px;
        height: 200px;
    }

    .roundDot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #195B40;
        margin-right: 5px;
    }

    .titleDot {
        color: #666666;
    }

    .titleDot:hover {
        color: #195B40;
    }

    .infoItem {
        cursor: pointer;
        padding: 4px 0;
    }

    .infoItem:hover {
        color: #195B40;
    }
</style>
